var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card-body",
    { staticClass: "video-selector" },
    [
      _vm.tab > 0
        ? _c(
            "v-layout",
            { attrs: { wrap: "" } },
            _vm._l(_vm.getSelectedVideos, function (video) {
              return _c(
                "v-flex",
                { key: video.id, attrs: { shrink: "" } },
                [
                  _c("Video", {
                    attrs: {
                      mission: _vm.mission,
                      videoDetail: video,
                      selectedMode: video.mode,
                      modes: video.mode
                        ? ["Delete"].concat(_vm.availableModes)
                        : _vm.availableModes,
                      modalOpen: _vm.modalOpen,
                    },
                    on: { missionUpdated: _vm.onMissionUpdated },
                  }),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "rtb-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "rtb-tab",
            { key: "record", attrs: { title: "Record" } },
            [
              _c("RecordVideo", {
                staticClass: "recording",
                attrs: {
                  videoTarget: _vm.user.id,
                  clientID: _vm.clientID,
                  videos: _vm.videos,
                },
              }),
            ],
            1
          ),
          _c(
            "rtb-tab",
            { key: "myVideos", attrs: { title: "My Videos" } },
            [
              _c(
                "rtb-card-body",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    _vm._l(_vm.myVideos, function (video) {
                      return _c(
                        "v-flex",
                        { key: video.id, attrs: { shrink: "" } },
                        [
                          _c("Video", {
                            attrs: {
                              mission: _vm.mission,
                              videoDetail: video,
                              modes: _vm.availableModes,
                              modalOpen: _vm.modalOpen,
                            },
                            on: { missionUpdated: _vm.onMissionUpdated },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rtb-tab",
            { key: "startedVideos", attrs: { title: "Started Videos" } },
            [
              _c(
                "rtb-card-body",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    _vm._l(_vm.getSelectedVideos, function (video) {
                      return _c(
                        "v-flex",
                        { key: video.id, attrs: { shrink: "" } },
                        [
                          _c("Video", {
                            attrs: {
                              mission: _vm.mission,
                              videoDetail: video,
                              modes: _vm.availableModes,
                              modalOpen: _vm.modalOpen,
                            },
                            on: { missionUpdated: _vm.onMissionUpdated },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rtb-tab",
            { key: "allVideos", attrs: { title: "All Videos" } },
            [
              _c(
                "rtb-card-body",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    _vm._l(_vm.allVideos, function (video) {
                      return _c(
                        "v-flex",
                        { key: video.id, attrs: { shrink: "" } },
                        [
                          _c("Video", {
                            attrs: {
                              mission: _vm.mission,
                              videoDetail: video,
                              modes: _vm.availableModes,
                              modalOpen: _vm.modalOpen,
                            },
                            on: { missionUpdated: _vm.onMissionUpdated },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }