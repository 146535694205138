var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-plyr", { ref: "player" }, [
        _c("audio", [_c("source", { attrs: { type: "audio/mp3" } })]),
      ]),
      _c("AudioList", {
        attrs: {
          title: "Room Music",
          items: _vm.audios[_vm.AudioCollection.Game],
        },
        on: {
          create: function ($event) {
            return _vm.initCreation(_vm.AudioCollection.Game)
          },
          edit: function ($event) {
            return _vm.initEdit($event, _vm.AudioCollection.Game)
          },
          select: function ($event) {
            return _vm.setAudio($event, _vm.AudioCollection.Game)
          },
        },
      }),
      _c("AudioList", {
        attrs: {
          title: "My User Clips",
          items: _vm.audios[_vm.AudioCollection.User],
        },
        on: {
          create: function ($event) {
            return _vm.initCreation(_vm.AudioCollection.User)
          },
          edit: function ($event) {
            return _vm.initEdit($event, _vm.AudioCollection.User)
          },
          select: function ($event) {
            return _vm.setAudio($event, _vm.AudioCollection.User)
          },
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "512px" },
          model: {
            value: _vm.modal,
            callback: function ($$v) {
              _vm.modal = $$v
            },
            expression: "modal",
          },
        },
        [
          _c("AudioSettings", {
            ref: "editor",
            attrs: { value: _vm.audio, collection: _vm.collection },
            on: {
              save: function ($event) {
                _vm.modal = false
              },
              copy: function ($event) {
                _vm.modal = false
              },
              delete: function ($event) {
                _vm.modal = false
              },
              cancel: function ($event) {
                _vm.modal = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }