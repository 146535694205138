<template>
  <rtb-card-body class="video-selector">
    <v-layout wrap v-if="tab > 0">
      <v-flex shrink v-for="video in getSelectedVideos" :key="video.id">
        <Video
          :mission="mission"
          :videoDetail="video"
          :selectedMode="video.mode"
          :modes="video.mode ? ['Delete', ...availableModes] : availableModes"
          :modalOpen="modalOpen"
          @missionUpdated="onMissionUpdated"
        />
      </v-flex>
    </v-layout>
    <rtb-tabs v-model="tab">
      <rtb-tab key="record" title="Record">
        <RecordVideo
          :videoTarget="user.id"
          :clientID="clientID"
          :videos="videos"
          class="recording"
        />
      </rtb-tab>
      <rtb-tab key="myVideos" title="My Videos">
        <rtb-card-body>
          <v-layout row wrap>
            <v-flex shrink v-for="video in myVideos" :key="video.id">
              <Video
                :mission="mission"
                :videoDetail="video"
                :modes="availableModes"
                :modalOpen="modalOpen"
                @missionUpdated="onMissionUpdated"
              />
            </v-flex>
          </v-layout>
        </rtb-card-body>
      </rtb-tab>
      <rtb-tab key="startedVideos" title="Started Videos">
        <rtb-card-body>
          <v-layout row wrap>
            <v-flex shrink v-for="video in getSelectedVideos" :key="video.id">
              <Video
                :mission="mission"
                :videoDetail="video"
                :modes="availableModes"
                :modalOpen="modalOpen"
                @missionUpdated="onMissionUpdated"
              />
            </v-flex>
          </v-layout>
        </rtb-card-body>
      </rtb-tab>
      <rtb-tab key="allVideos" title="All Videos">
        <rtb-card-body>
          <v-layout row wrap>
            <v-flex shrink v-for="video in allVideos" :key="video.id">
              <Video
                :mission="mission"
                :videoDetail="video"
                :modes="availableModes"
                :modalOpen="modalOpen"
                @missionUpdated="onMissionUpdated"
              />
            </v-flex>
          </v-layout>
        </rtb-card-body>
      </rtb-tab>
    </rtb-tabs>
  </rtb-card-body>
</template>

<script>
import { db } from "@/firebase"

import { RtbCardBody, RtbTabs, RtbTab } from "@/components/ui"
import Video from "./Video.vue"
import RecordVideo from "./RecordVideo.vue"

import { mapGetters, mapState } from "vuex"

export default {
  name: "VideoSelector",
  components: {
    Video,
    RtbCardBody,
    RtbTabs,
    RtbTab,
    RecordVideo
  },
  data() {
    return {
      tab: 0,
      loading: true,
      videos: {},
      myVideos: [],
      hostlessSubscriptionRefCount: 0,
      missionVideoSubscriptionRefCount: 0,
      missionVideos: {},
      allVideos: []
    }
  },
  async created() {
    this.subscribeToMyHostlessVideo()
    await this.getAllVideos()
  },
  beforeDestroy() {
    this.unSubscribeToMyHostlessVideo()
    this.unSubscribeFromMissionVideos()
  },
  props: {
    mission: {
      required: true
    },
    modalOpen: {
      required: true,
      default: false
    },
    updateLocalMission: {
      type: Function,
      default: () => {}
    }
  },
  watch: {
    "mission.id": {
      handler() {
        this.subscribeToMissionVideos()
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters("auth", ["clientID", "user"]),
    ...mapState("group", ["modes"]),
    ...mapGetters(["gameStatus", "gameID", "orgID"]),
    ...mapState("group", ["modes"]),
    hostlessSubscriptionRef() {
      return db
        .auxiliary()
        .ref(`client/${this.clientID}/HostlessRecordings`)
        .orderByChild("target")
        .equalTo(this.user?.id)
    },
    missionVideosRef() {
      return db
        .auxiliary()
        .ref(
          `org/${this.orgID}/game/${this.gameID}/missions/${this.mission.id}/hostless-video`
        )
    },
    getSelectedVideos() {
      return this.modes
        .filter(mode => !!this.missionVideos?.[mode]?.["key"])
        .map(mode => ({
          ...this.missionVideos?.[mode],
          mode,
          key: this.missionVideos?.[mode]?.["key"]
        }))
    },
    availableModes() {
      return this.modes.filter(mode => this.mission?.[mode])
    }
  },
  methods: {
    async getAllVideos() {
      const videosSnap = await db
        .auxiliary()
        .ref(`client/${this.clientID}/HostlessRecordings`)
        .orderByChild("target")
        .limitToLast(5000)
        .once("value")
      const videos = Object.entries(videosSnap.val() ?? {}).map(
        ([key, value]) => ({ ...value, key })
      )
      this.allVideos = videos
    },
    subscribeToMyHostlessVideo() {
      this.unSubscribeToMyHostlessVideo()
      this.hostlessSubscriptionRef.on("value", snapshot => {
        const videos = snapshot?.val() ?? {}
        this.videos = videos
        this.myVideos = Object.entries(videos).map(([key, value]) => ({
          ...value,
          key
        }))
      })
    },
    unSubscribeToMyHostlessVideo() {
      this.hostlessSubscriptionRef.off()
    },
    subscribeToMissionVideos() {
      if (this.missionVideoSubscriptionRefCount > 1) {
        this.unSubscribeFromMissionVideos()
      }

      this.missionVideoSubscriptionRefCount += 1

      this.missionVideosRef.on("value", snapshot => {
        this.missionVideos = snapshot?.val() ?? {}
      })
    },
    unSubscribeFromMissionVideos() {
      this.missionVideosRef.off()
      this.missionVideoSubscriptionRefCount = 0
    },
    onMissionUpdated(mission) {
      this.updateLocalMission(mission)
    }
  }
}
</script>

<style lang="scss">
.video-selector {
  max-height: 400px;
  overflow: auto;
  .recording {
    max-width: 600px;
    max-height: 500px;
    width: 60%;
    height: 60%;
  }
  .popup-button {
    @extend .rtb-box-shadow;
    background-color: $primary_accent_color;
    color: $primary_accent_color;
    border-radius: 4px;
    height: 28px;
    line-height: 28px;
    min-width: 90px;
    padding: 0 10px;
    margin: auto;
    font-weight: bold;
    font-size: 13px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    &.disabled {
      background-color: $color-disabled;
    }
  }
}
</style>
