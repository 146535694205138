import type { Mission } from "@/types/mission"

export function reorderMissions(
  missions: Mission[],
  oldIndex: number,
  newIndex: number
) {
  const copy = [...missions]
  const target = copy.splice(oldIndex, 1)[0]
  copy.splice(newIndex, 0, target)
  return copy.map((mission, i) => ({ ...mission, pos: i }))
}
