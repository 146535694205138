var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "video", attrs: { column: "" } },
    [
      _c(
        "v-flex",
        { staticClass: "shrink top-control" },
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              !!_vm.selectedMode
                ? [
                    _c("v-flex", { staticClass: "mode shrink" }, [
                      _vm._v(_vm._s(_vm.selectedMode)),
                    ]),
                  ]
                : _c(
                    "v-flex",
                    { staticClass: "shrink copy" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        "aria-label": "Copy Url",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            staticClass: "contrast-text-color",
                                            on: {
                                              click: function ($event) {
                                                return _vm.copyVideoUrl(
                                                  _vm.videoDetail.url
                                                )
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [_vm._v(" share ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.getUrlCopyText) + " "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
              _c(
                "v-flex",
                {
                  staticClass:
                    "d-flex shrink buttons add-to-mission justify-end",
                  staticStyle: { "margin-left": "auto" },
                },
                [
                  _c("button", [
                    _c("span", { staticClass: "contrast-text-color" }, [
                      _vm._v(" + "),
                    ]),
                  ]),
                  _c("v-select", {
                    ref: "selectedMode",
                    attrs: {
                      items: _vm.modes,
                      label: "Play Type",
                      required: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c("div", [_vm._v(" " + _vm._s(data.item) + " ")]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.mode,
                      callback: function ($$v) {
                        _vm.mode = $$v
                      },
                      expression: "mode",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "video-wrap d-flex grow", attrs: { column: "" } },
        [
          [
            _vm.videoDetail.url
              ? _c("VideoComponent", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isPlaying,
                      expression: "isPlaying",
                    },
                  ],
                  ref: "video",
                  style: _vm.videoDetail.flipped
                    ? "transform: scaleX(1) !important"
                    : null,
                  attrs: { src: _vm.videoDetail.url },
                  on: {
                    ended: function ($event) {
                      _vm.isPlaying = false
                    },
                  },
                })
              : _vm._e(),
            !_vm.isPlaying
              ? _c("v-flex", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(_vm.videoDetail.title) + " "),
                ])
              : _vm._e(),
            _c(
              "v-flex",
              { staticClass: "d-flex play-pause shrink" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "contrast-text-color",
                    attrs: { icon: "", small: "" },
                    on: { click: _vm.onPausePlay },
                  },
                  [
                    _c("v-icon", [
                      _vm._v(
                        _vm._s(
                          !_vm.isPlaying ? "play_circle" : "pause_circle_filled"
                        ) + " "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
      _c("v-layout", { staticClass: "add-time shrink" }, [
        _c(
          "div",
          { staticClass: "add-time__input" },
          [
            _c("v-text-field", {
              ref: "additionalSeconds",
              attrs: { solo: "", placeholder: "seconds", "hide-details": "" },
              on: { keyup: _vm.onAddTime },
              model: {
                value: _vm.addTime,
                callback: function ($$v) {
                  _vm.addTime = _vm._n($$v)
                },
                expression: "addTime",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }