<template>
  <v-dialog v-model="visible" persistent no-click-animation max-width="320">
    <rtb-card>
      <rtb-card-body>
        <rtb-select
          v-model="localMissionID"
          :options="localMissions"
          label="Title"
          identity="theKey"
          option-text="name"
          :get-option-key="getOptionKey"
          class="mb-3"
          ><template #help
            ><rtb-inline-help>Placeholder</rtb-inline-help></template
          ></rtb-select
        >
        <rtb-select
          v-if="linkToMissions.length"
          v-model="linkMissionID"
          :options="linkToMissions"
          label="Link to"
          identity="theKey"
          option-text="name"
          class="mb-3"
        >
          <template #help
            ><rtb-inline-help
              >Link previous selected mission to this</rtb-inline-help
            ></template
          ></rtb-select
        >
        <rtb-select
          v-model="missionID"
          :options="missions"
          label="Place after"
          identity="theKey"
          option-text="name"
        >
          <template #help
            ><rtb-inline-help>Placeholder</rtb-inline-help></template
          ></rtb-select
        >
      </rtb-card-body>
      <rtb-card-actions>
        <rtb-button color="dark" @click="closeModal">Close</rtb-button>
        <rtb-button v-show="missionID && localMissionID" @click="submitHandler"
          >Submit</rtb-button
        >
      </rtb-card-actions>
    </rtb-card>
  </v-dialog>
</template>

<script>
import Vue from "vue"
import { uniqBy, flatten } from "lodash"
import { fetchGamesByRunStatus, fetchMissions } from "@/services/game.service"
import { ModalMixin } from "../../mixins/Modal"
import MissionType from "@shared/enums/Mission"

import {
  RtbButton,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbSelect,
  RtbInlineHelp
} from "@/components/ui"

import { Game } from "@/helpers"

export default Vue.extend({
  name: "MissionAddModal",
  components: {
    RtbButton,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbSelect,
    RtbInlineHelp
  },
  mixins: [ModalMixin],
  props: {
    missions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      missionID: "",
      localMissionID: "",
      localMissions: [],
      linkMissionID: null
    }
  },
  computed: {
    linkToMissions() {
      const selectedMission = this.localMissions.find(
        mission => mission?.theKey == this.localMissionID
      )

      const behavior = selectedMission?.behavior

      if (MissionType.FactMatch === behavior) {
        return this.missions.filter(({ behavior }) =>
          this.isFreeFormMissions.includes(behavior)
        )
      } else if (MissionType.TwoTruthsReveal === behavior) {
        return this.missions.filter(
          mission => MissionType.TwoTruths === mission?.behavior
        )
      }
      return []
    },
    isFreeFormMissions() {
      return [
        MissionType.Freeform,
        MissionType.TakePhoto,
        MissionType.Photo,
        MissionType.PhotoBooth,
        MissionType.Giphy,
        MissionType.Represent
      ]
    }
  },
  async mounted() {
    const { orgID } = this.$store.getters
    const games = await fetchGamesByRunStatus({
      orgID,
      value: Game.GAME_RUN_STATUS.MASTERS
    })

    const IDs = Object.keys(games ?? {})
    const promises = IDs.map(gameID => fetchMissions({ orgID, gameID }))
    const res = await Promise.all(promises)
    this.localMissions = uniqBy(
      flatten(
        res.map(missions =>
          Object.entries(missions || {})
            .filter(([id, mission]) => id && mission?.behavior)
            .map(([id, mission]) => ({ ...mission, id, theKey: id }))
        )
      ),
      "name"
    )
  },
  methods: {
    titlePredicate(mission) {
      return mission.title
    },
    submitHandler() {
      const { missionID, localMissionID, linkMissionID } = this
      this.$emit("create", {
        afterID: missionID,
        linkMissionID,
        mission: this.getLocalMission(localMissionID)
      })
      this.closeModal()
    },
    getLocalMission(id) {
      return this.localMissions.find(mission => mission.theKey === id)
    },
    getOptionKey(option) {
      return option.theKey
    }
  }
})
</script>
