<template>
  <v-layout column class="video">
    <v-flex class="shrink top-control">
      <v-layout row>
        <template v-if="!!selectedMode">
          <v-flex class="mode shrink">{{ selectedMode }}</v-flex>
        </template>
        <v-flex class="shrink copy" v-else>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon small aria-label="Copy Url">
                <v-icon
                  v-on="on"
                  @click="copyVideoUrl(videoDetail.url)"
                  class="contrast-text-color"
                >
                  share
                </v-icon>
              </v-btn>
            </template>
            <span> {{ getUrlCopyText }} </span>
          </v-tooltip>
        </v-flex>
        <v-flex
          class="d-flex shrink buttons add-to-mission justify-end"
          style="margin-left: auto"
        >
          <button>
            <span class="contrast-text-color"> + </span>
          </button>
          <v-select
            :items="modes"
            v-model="mode"
            label="Play Type"
            required
            ref="selectedMode"
          >
            <template slot="item" slot-scope="data">
              <div>
                {{ data.item }}
              </div>
            </template>
          </v-select>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-layout column class="video-wrap d-flex grow">
      <template>
        <VideoComponent
          v-show="isPlaying"
          v-if="videoDetail.url"
          :src="videoDetail.url"
          ref="video"
          :style="
            videoDetail.flipped ? `transform: scaleX(1) !important` : null
          "
          @ended="isPlaying = false"
        />
        <v-flex class="title" v-if="!isPlaying">
          {{ videoDetail.title }}
        </v-flex>
        <v-flex class="d-flex play-pause shrink">
          <v-btn icon small class="contrast-text-color" @click="onPausePlay">
            <v-icon
              >{{ !isPlaying ? "play_circle" : "pause_circle_filled" }}
            </v-icon>
          </v-btn>
        </v-flex>
      </template>
    </v-layout>
    <v-layout class="add-time shrink">
      <div class="add-time__input">
        <v-text-field
          v-model.number="addTime"
          solo
          placeholder="seconds"
          hide-details
          @keyup="onAddTime"
          ref="additionalSeconds"
        />
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import { copyToClipboard } from "@/helpers"
import { db } from "@/firebase"

import { mapGetters } from "vuex"

import VideoComponent from "@/components/ui/VideoComponent/VideoComponent.vue"

export default {
  name: "Video",
  components: { VideoComponent },
  data() {
    return {
      mode: this.selectedMode,
      isPlaying: false,
      urlCopyText: null,
      addTime: ""
    }
  },
  computed: {
    ...mapGetters(["orgID", "gameID"]),
    getUrlCopyText() {
      return this.urlCopyText ?? this.videoDetail.url ?? null
    },
    modeTime() {
      return this.mission?.["custom-time"]?.[this.selectedMode]
    }
  },
  watch: {
    async mode(mode) {
      if (!mode) return
      let modeToSet, modeValue
      if (mode === "Delete") {
        modeToSet = this.selectedMode
        modeValue = null
      } else {
        modeToSet = mode
        modeValue = this.videoDetail.key
      }
      console.log(modeToSet, modeValue)
      const updateValue = !modeValue
        ? modeValue
        : { ...this.videoDetail, key: modeValue, url: this.videoDetail.url }

      this.$emit("missionUpdated", {
        ...this.mission,
        ["hostless-video"]: {
          ...this.mission?.["hostless-video"],
          [modeToSet]: updateValue
        }
      })

      await db
        .auxiliary()
        .ref(
          `org/${this.orgID}/game/${this.gameID}/missions/${this.mission.id}/hostless-video`
        )
        .update({
          [modeToSet]: updateValue
        })
      this.$refs?.selectedMode?.reset()
    },
    modalOpen(val) {
      if (!val && this.isPlaying) {
        this.pauseVideo()
      }
    },
    modeTime: {
      handler(val) {
        if (val >= 0) {
          this.addTime = val
        }
      },
      immediate: true
    }
  },
  props: {
    mission: {
      required: true
    },
    videoDetail: {
      type: Object,
      default: () => ({
        id: 0,
        name: "morgan video"
      })
    },
    selectedMode: {
      required: false,
      default: null
    },
    modes: {
      type: Array,
      default: () => []
    },
    modalOpen: {
      default: false
    }
  },
  methods: {
    onPausePlay() {
      !this.isPlaying ? this.playVideo() : this.pauseVideo()
    },
    playVideo() {
      const { video } = this.$refs
      if (video) {
        this.isPlaying = true
        video.play().catch(err => {
          console.log("caught an error", err)
          this.isPlaying = false
        })
      }
    },
    pauseVideo() {
      const { video } = this.$refs
      if (video) {
        video.pause()
        video.currentTime = 0
        this.isPlaying = false
      }
    },
    copyVideoUrl(text) {
      copyToClipboard(text)

      this.urlCopyText = "Video url copied!"
      setTimeout(() => {
        this.urlCopyText = null
      }, 1500)
    },
    onAddTime: _.debounce(function () {
      const duration = parseInt(this.addTime) || 0
      const mission = this.mission || {}
      this.$emit("missionUpdated", {
        ...mission,
        ["custom-time"]: {
          ...(mission["custom-time"] || {}),
          [this.selectedMode]: duration
        }
      })
    }, 800)
  }
}
</script>

<style lang="scss">
.video {
  position: relative;
  width: 130px;
  height: 130px;
  margin-right: 3px;

  .mode {
    background: var(--primary-accent-color);
    border-bottom-right-radius: 5px;
    padding: 3px;
  }
  .add-time {
    margin: 0px 4px;
    text-align: center;
    align-self: center;
    &__input {
      border-radius: 2px;
      .v-text-field.v-text-field--solo .v-input__control {
        min-height: 35%;
        padding: 0;
        height: 22px;
        max-height: 30px;
        width: 80px;
        margin-top: 3px;
        .v-input__slot {
          padding: 0 5px;
        }
      }
    }
  }
  .copy {
    display: grid;
    place-content: center;
    .v-btn {
      height: 25px;
      width: 25px;
      margin: 0px;
      background: var(--primary-accent-color);
    }
    .v-icon {
      font-size: 18px;
      font-weight: bolder;
    }
  }

  .buttons {
    position: relative;
  }
  .add-to-mission {
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0px;
    }
    button {
      position: relative;
      width: 100%;
      background: var(--primary-accent-color);
      text-transform: uppercase;
      color: var(--primary-accent-color);
      border-radius: 50%;
      font-weight: bolder;
      display: grid;
      place-content: center;
      font-size: 24px;
      height: 25px;
      width: 25px;
    }
    .v-select {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      padding: 0;
      overflow: hidden;
    }
  }
  .video-wrap {
    position: relative;
    align-items: center;
    justify-content: center;
    video {
      position: absolute;
      left: 0;
      bottom: 0;
      // height: 100%;
      width: 100%;
    }
    .title {
      position: relative;
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 20px;
      position: absolute;
      left: 0;
      border: 0;
      overflow: hidden;
    }
  }
  .play-pause {
    align-items: flex-end;
    .v-btn {
      height: 25px;
      margin: 0px;
    }
  }
}
</style>
