








































import Vue from "vue"
import invarinat from "invariant"
import type { WithRefs } from "vue-typed-refs"

import { AudioCollection, AudioEntry } from "@/modules/audio/types"

import AudioSettings from "@/components/GroupTeams/Common/AudioSettings.vue"
import AudioList from "@/components/GroupTeams/Common/AudioList.vue"

export type Refs = {
  editor: InstanceType<typeof AudioSettings>
}

export default (Vue as WithRefs<Refs>).extend({
  components: {
    AudioSettings,
    AudioList
  },
  data() {
    return {
      audios: {
        [AudioCollection.User]: [],
        [AudioCollection.Game]: []
      },
      modal: false,
      audio: null,
      collection: AudioCollection.Game
    }
  },
  computed: {
    orgID(): string {
      return this.$store.getters.orgID
    },
    gameID(): string {
      return this.$store.getters.gameID
    },
    userID(): string {
      return this.$store.getters.user.id
    }
  },
  watch: {
    modal(value) {
      if (value === false) {
        this.$refs.editor.reset()
        this.audio = null
      }
    }
  },
  created() {
    this._unsubscribe = {
      [AudioCollection.User]: null,
      [AudioCollection.Game]: null
    }

    this.subscribe()

    this.AudioCollection = AudioCollection
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  methods: {
    subscribe() {
      return this.$services.get("audio").then(service => {
        this._unsubscribe[AudioCollection.User] = service.subscribe(
          AudioCollection.User,
          { orgID: this.orgID, userID: this.userID },
          audios => {
            this.audios[AudioCollection.User] = audios
          }
        )

        this._unsubscribe[AudioCollection.Game] = service.subscribe(
          AudioCollection.Game,
          { orgID: this.orgID, gameID: this.gameID },
          audios => {
            this.audios[AudioCollection.Game] = audios
          }
        )
      })
    },
    unsubscribe() {
      for (const key in this._unsubscribe) {
        this._unsubscribe[key]()
      }
    },
    initCreation(collection: AudioCollection) {
      this.collection = collection
      this.modal = true
      this.$refs.editor.init()
    },
    initEdit(audioID: string, collection: AudioCollection) {
      this.audio = this._getAudioByID(audioID, collection)
      this.collection = collection
      this.modal = true
    },
    setAudio(audioID: string, collection: AudioCollection) {
      this.audio = this._getAudioByID(audioID, collection)
      const { player } = this.$refs.player
      player.source = {
        type: "audio",
        title: this.audio.name,
        sources: [
          {
            src: this.audio.source,
            type: "audio/mp3"
          }
        ]
      }
      player.volume = this.audio.volume / 100
      player.play()
    },
    _getAudioByID(audioID: string, collection: AudioCollection) {
      const audio = this.audios[collection].find(audio => audio.id === audioID)
      invarinat(audio, `Audio with id "${audioID}" not found`)
      return audio
    }
  }
})
