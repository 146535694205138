var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.game
    ? _c(
        "rtb-card",
        {
          staticClass: "relative edit-game h-full",
          class: { "edit-game--frozen": _vm.game.frozen },
        },
        [
          _c("div", { staticClass: "edit-game__top" }, [
            _c(
              "div",
              { staticClass: "edit-game__top-left" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { circle: "", small: "", fab: "", color: "orange" },
                    on: {
                      click: function ($event) {
                        _vm.isMisssionAddModalVisible = true
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("add")])],
                  1
                ),
                _vm.activityState.value
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          circle: "",
                          small: "",
                          fab: "",
                          color: "orange",
                        },
                        on: { click: _vm.closeActivityPanel },
                      },
                      [
                        _c(
                          "v-icon",
                          { key: "close", staticStyle: { margin: "-4px 0" } },
                          [_vm._v("close")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-btn",
                      {
                        attrs: {
                          circle: "",
                          small: "",
                          fab: "",
                          color: "orange",
                        },
                        on: { click: _vm.openActivtyPanel },
                      },
                      [
                        _c("SvgIcon", {
                          attrs: {
                            name: "controller",
                            width: "20",
                            height: "20",
                          },
                        }),
                      ],
                      1
                    ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-game__top-right" },
              [
                _c(
                  "v-btn",
                  {
                    staticStyle: { "z-index": "10" },
                    attrs: { circle: "", small: "", fab: "", color: "purple" },
                    on: {
                      click: function ($event) {
                        return _vm.openEditSettings()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("settings")])],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "csv-download",
                    attrs: { circle: "", small: "", fab: "", color: "orange" },
                    on: {
                      click: function ($event) {
                        return _vm.exportCSV()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("download")])],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "customer-games",
                    class: { spin: _vm.importingData },
                    attrs: { circle: "", small: "", fab: "", color: "green" },
                    on: {
                      click: function ($event) {
                        return _vm.onSaveCustomerMissions()
                      },
                    },
                  },
                  [
                    !_vm.importingData
                      ? _c("v-icon", [_vm._v("cloud_download")])
                      : _c("v-icon", [_vm._v("refresh")]),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { circle: "", small: "", fab: "", color: "orange" },
                    on: {
                      click: function ($event) {
                        return _vm.add()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("add")])],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-card-title",
            [
              _c(
                "h2",
                { staticClass: "white--text" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.game.externalName) +
                      " - " +
                      _vm._s(_vm.game.name) +
                      " - " +
                      _vm._s(_vm.game.description) +
                      " "
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "sortHandle white",
                      staticStyle: { "z-index": "10" },
                      attrs: { icon: "" },
                      on: { click: _vm.pushToGame },
                    },
                    [_c("v-icon", [_vm._v("flight_takeoff")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "sortHandle white",
                      attrs: { icon: "" },
                      on: { click: _vm.pushToGameUsers },
                    },
                    [_c("v-icon", [_vm._v("face")])],
                    1
                  ),
                  _vm.selectedMissions
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "sortHandle white",
                          attrs: { icon: "", color: "red\n      " },
                          on: { click: _vm.deleteMissions },
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.selectedMissions
                ? _c("v-select", {
                    staticStyle: { "max-width": "200px" },
                    attrs: {
                      items: _vm.missionPositions,
                      "item-text": "name",
                      "item-value": "id",
                      label: "Move Missions After",
                    },
                    model: {
                      value: _vm.moveMissionsPos,
                      callback: function ($$v) {
                        _vm.moveMissionsPos = $$v
                      },
                      expression: "moveMissionsPos",
                    },
                  })
                : _vm._e(),
              _vm.selectedMissions
                ? _c(
                    "v-btn",
                    {
                      staticClass: "sortHandle white",
                      attrs: { icon: "", color: "blue" },
                      on: { click: _vm.moveMissions },
                    },
                    [_c("v-icon", [_vm._v("swap_vert")])],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c("rtb-text-input", {
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("rtb-checkbox", {
                staticStyle: { "margin-left": "20px", "margin-top": "15px" },
                attrs: {
                  "append-icon": "flagged",
                  label: "Flagged",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.flagged,
                  callback: function ($$v) {
                    _vm.flagged = $$v
                  },
                  expression: "flagged",
                },
              }),
            ],
            1
          ),
          _c("rtb-table"),
          _c("v-data-table", {
            ref: "sortableTable",
            staticClass: "elevation-1 rtb-table missions-table",
            attrs: {
              headers: _vm.headers,
              items: _vm.allMissions,
              search: _vm.search,
              "item-key": "theKey",
              length: "10",
              dense: "",
              width: "1200px",
              "select-all": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "items",
                  fn: function (props) {
                    return [
                      _c(
                        "tr",
                        {
                          key: _vm.itemKey(props.item),
                          staticClass: "sortableRow",
                          attrs: { id: _vm.behaviorSafe(props.item.behavior) },
                        },
                        [
                          _c(
                            "td",
                            [
                              _c("v-checkbox", {
                                attrs: { primary: "", "hide-details": "" },
                                model: {
                                  value: props.selected,
                                  callback: function ($$v) {
                                    _vm.$set(props, "selected", $$v)
                                  },
                                  expression: "props.selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "px-1",
                              staticStyle: { width: "0.1%" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "sortHandle",
                                  staticStyle: { cursor: "move" },
                                  attrs: { icon: "" },
                                },
                                [_c("v-icon", [_vm._v("drag_handle")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("td", { staticStyle: { width: "48px" } }, [
                            _vm._v(_vm._s(props.item.pos)),
                          ]),
                          _c(
                            "td",
                            { staticStyle: { width: "160px" } },
                            [
                              _c("rtb-inline-edit", {
                                on: {
                                  change: function ($event) {
                                    return _vm.updateMission(props.item)
                                  },
                                },
                                model: {
                                  value: props.item.name,
                                  callback: function ($$v) {
                                    _vm.$set(props.item, "name", $$v)
                                  },
                                  expression: "props.item.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { width: "48px" } },
                            [
                              _c("rtb-inline-edit", {
                                on: {
                                  change: function ($event) {
                                    return _vm.updateMission(props.item)
                                  },
                                },
                                model: {
                                  value: props.item.points,
                                  callback: function ($$v) {
                                    _vm.$set(props.item, "points", $$v)
                                  },
                                  expression: "props.item.points",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _vm.shouldTruncate(props.item.instructions)
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "word-break": "break-all",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.truncate(
                                              props.item.instructions
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c("rtb-inline-edit", {
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateMission(props.item)
                                      },
                                    },
                                    model: {
                                      value: props.item.instructions,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          props.item,
                                          "instructions",
                                          $$v
                                        )
                                      },
                                      expression: "props.item.instructions",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _vm.shouldTruncate(props.item.answer, 30)
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.truncate(props.item.answer, 30)
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("rtb-inline-edit", {
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateMission(props.item)
                                      },
                                    },
                                    model: {
                                      value: props.item.answer,
                                      callback: function ($$v) {
                                        _vm.$set(props.item, "answer", $$v)
                                      },
                                      expression: "props.item.answer",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { width: "160px" } },
                            [
                              props.item.photo
                                ? _c("svg-icon", {
                                    attrs: {
                                      name: "image",
                                      height: "25",
                                      width: "25",
                                    },
                                  })
                                : _vm._e(),
                              props.item.youtube
                                ? _c("svg-icon", {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      name: "video",
                                      height: "25",
                                      width: "25",
                                    },
                                  })
                                : _vm._e(),
                              props.item.autoMissionAudio
                                ? _c("svg-icon", {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      name: "headset",
                                      height: "25",
                                      width: "25",
                                    },
                                  })
                                : _vm._e(),
                              _vm.hasHostlessVideo(props.item)
                                ? _c("svg-icon", {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      name: "become-host3",
                                      width: "25",
                                      height: "25",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { width: "160px" } },
                            [
                              _c("rtb-select", {
                                attrs: {
                                  options: _vm.playTypes,
                                  label: "Play Type",
                                  "hide-label": "",
                                  variant: "transparent",
                                  "append-to-body": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updateMission(props.item)
                                  },
                                },
                                model: {
                                  value: props.item.playType,
                                  callback: function ($$v) {
                                    _vm.$set(props.item, "playType", $$v)
                                  },
                                  expression: "props.item.playType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { width: "96px" } },
                            [
                              _c("rtb-select", {
                                attrs: {
                                  options: _vm.tries,
                                  label: "Tries",
                                  "hide-label": "",
                                  variant: "transparent",
                                  "append-to-body": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updateMission(props.item)
                                  },
                                },
                                model: {
                                  value: props.item.numOfTries,
                                  callback: function ($$v) {
                                    _vm.$set(props.item, "numOfTries", $$v)
                                  },
                                  expression: "props.item.numOfTries",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("td", { staticStyle: { width: "48px" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.modeLetters(props.item)) + " "
                            ),
                          ]),
                          _c(
                            "td",
                            { staticStyle: { width: "48px" } },
                            [
                              _c("rtb-inline-edit", {
                                on: {
                                  change: function ($event) {
                                    return _vm.updateMission(props.item)
                                  },
                                },
                                model: {
                                  value: props.item.time,
                                  callback: function ($$v) {
                                    _vm.$set(props.item, "time", $$v)
                                  },
                                  expression: "props.item.time",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("td", { staticStyle: { width: "148px" } }, [
                            _c(
                              "div",
                              { staticClass: "rtb-d-flex" },
                              [
                                _c(
                                  "rtb-button",
                                  {
                                    staticClass: "mr-1",
                                    attrs: {
                                      variant: "icon",
                                      "aria-label": "Edit",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(props.item)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { name: "pen-regular" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "rtb-button",
                                  {
                                    staticClass: "mr-1",
                                    attrs: {
                                      color: "secondary",
                                      variant: "icon",
                                      "aria-label": "Copy",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copy(props.item)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { name: "copy-regular" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.canDelete
                                  ? _c(
                                      "rtb-button",
                                      {
                                        attrs: {
                                          color: "danger",
                                          variant: "icon",
                                          "aria-label": "Delete",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openRemoveDialog(
                                              props.item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { name: "trash-regular" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3096968222
            ),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
          _vm.deleting
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500px" },
                  model: {
                    value: _vm.deleting,
                    callback: function ($$v) {
                      _vm.deleting = $$v
                    },
                    expression: "deleting",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Delete Mission")]),
                      _c("v-card-text", [
                        _vm._v("Are you sure want to delete this mission?"),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-layout",
                            { attrs: { "justify-center": "" } },
                            [
                              _c(
                                "rtb-button",
                                {
                                  ref: "removeBtn",
                                  attrs: {
                                    color: "danger",
                                    "aria-label": "Remove",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove()
                                    },
                                  },
                                },
                                [_vm._v(" Remove ")]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    color: "grey",
                                    "aria-label": "Cancel",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.deleting = false
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("mission-add-modal", {
            attrs: { missions: _vm.allMissions },
            on: { create: _vm.onCreateMission },
            model: {
              value: _vm.isMisssionAddModalVisible,
              callback: function ($$v) {
                _vm.isMisssionAddModalVisible = $$v
              },
              expression: "isMisssionAddModalVisible",
            },
          }),
          _vm.editing
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    persistent: "",
                    "no-click-animation": "",
                    width: "80%",
                    "content-class": "overflow-auto mission-edit-modal",
                  },
                  model: {
                    value: _vm.editing,
                    callback: function ($$v) {
                      _vm.editing = $$v
                    },
                    expression: "editing",
                  },
                },
                [
                  _c(
                    "rtb-card",
                    [
                      _c(
                        "rtb-tabs",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "rtb-tab",
                            { key: "general", attrs: { title: "General" } },
                            [
                              _vm.mission
                                ? _c(
                                    "rtb-card-body",
                                    [
                                      _c(
                                        "rtb-row",
                                        [
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "1" } },
                                            [
                                              _c("rtb-text-input", {
                                                attrs: {
                                                  label: "Position",
                                                  rules: {
                                                    numeric: true,
                                                    min_value: 0,
                                                    max_value:
                                                      _vm.allMissions.length -
                                                      1,
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                "Position in list"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3367742363
                                                ),
                                                model: {
                                                  value: _vm.mission.pos,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "pos",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "mission.pos",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "2" } },
                                            [
                                              _c("rtb-text-input", {
                                                attrs: {
                                                  label: "Name",
                                                  rules: _vm.rules.MissionName,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                "What we refer to the mission"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  494180407
                                                ),
                                                model: {
                                                  value: _vm.mission.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "mission.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "2" } },
                                            [
                                              _c("rtb-text-input", {
                                                attrs: {
                                                  label: "Title",
                                                  rules: _vm.rules.MissionTitle,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                "What the players see"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  692392039
                                                ),
                                                model: {
                                                  value: _vm.mission.title,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "title",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "mission.title",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "2" } },
                                            [
                                              _c("rtb-select", {
                                                attrs: {
                                                  options: _vm.behaviors,
                                                  rules: _vm.rules.MissionType,
                                                  label: "Behavior",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.setDefaultModes(
                                                      _vm.mission
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .InlineHelpText
                                                                    .MissionType
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  997997653
                                                ),
                                                model: {
                                                  value: _vm.mission.behavior,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "behavior",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.behavior",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.mission.behavior == "Info"
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "2" } },
                                                [
                                                  _c("rtb-checkbox", {
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      "margin-top": "30px",
                                                    },
                                                    attrs: {
                                                      label: "Block Marker",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.blockMarker,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "blockMarker",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.blockMarker",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.mission.behavior == "Poll"
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "5" } },
                                                [
                                                  _c("rtb-checkbox", {
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      "margin-top": "30px",
                                                    },
                                                    attrs: { label: "TRW" },
                                                    model: {
                                                      value: _vm.mission.trw,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "trw",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "mission.trw",
                                                    },
                                                  }),
                                                  _c("rtb-checkbox", {
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      "margin-top": "30px",
                                                    },
                                                    attrs: {
                                                      label: "Host Choice",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .trwHostChoice,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "trwHostChoice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.trwHostChoice",
                                                    },
                                                  }),
                                                  _c("rtb-checkbox", {
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      "margin-top": "30px",
                                                    },
                                                    attrs: {
                                                      label: "Nominate scribe",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.nominateScribeChange,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .nominateScribe,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "nominateScribe",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.nominateScribe",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.mission.trw
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "2" } },
                                                [
                                                  _c("rtb-select", {
                                                    attrs: {
                                                      options:
                                                        _vm.trwRandomOverride,
                                                      label: "Random Override",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "help",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "rtb-inline-help",
                                                                [
                                                                  _vm._v(
                                                                    "TRW Random which overrides game settings"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1847224444
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .trwRandomOverride,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "trwRandomOverride",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.trwRandomOverride",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.mission.trw
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "2" } },
                                                [
                                                  _c("rtb-select", {
                                                    attrs: {
                                                      options:
                                                        _vm.trwOptionsOverride,
                                                      label: "TRW Options",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "help",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "rtb-inline-help",
                                                                [
                                                                  _vm._v(
                                                                    "Number of options selected for the TRW which overrides game setting"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3842890661
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .trwOptionsOverride,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "trwOptionsOverride",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.trwOptionsOverride",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.mission.behavior == "Slides"
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "1" } },
                                                [
                                                  _c("rtb-checkbox", {
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      "margin-top": "30px",
                                                    },
                                                    attrs: {
                                                      label:
                                                        "Karaoke Powerpoint",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .karaokePowerpoint,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "karaokePowerpoint",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.karaokePowerpoint",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isPriceIsRight
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "2" } },
                                                [
                                                  _c("rtb-checkbox", {
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      "margin-top": "30px",
                                                    },
                                                    attrs: {
                                                      label:
                                                        "Do NOT use currency",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.noCurrency,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "noCurrency",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.noCurrency",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.mission.behavior == "Unlock"
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "4" } },
                                                [
                                                  _c("rtb-select", {
                                                    attrs: {
                                                      options: _vm.escapeRooms,
                                                      "option-text": "label",
                                                      identity: "id",
                                                      label: "Game to Unlock",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "help",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "rtb-inline-help",
                                                                [
                                                                  _vm._v(
                                                                    " This is the game that will be unlocked by the players who complete this mission "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2479756124
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .unlockGameID,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "unlockGameID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.unlockGameID",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.idDefaultAnswer
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "6" } },
                                                [
                                                  _c("rtb-text-input", {
                                                    attrs: { label: "Answer" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "help",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "rtb-inline-help",
                                                                [
                                                                  _vm._v(
                                                                    "The answer, which can be multiple when separated by commas"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2849508344
                                                    ),
                                                    model: {
                                                      value: _vm.mission.answer,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isLink
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "5" } },
                                                [
                                                  _c("rtb-text-input", {
                                                    attrs: {
                                                      label: "Link URL",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "help",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "rtb-inline-help",
                                                                [
                                                                  _vm._v(
                                                                    "The link that players can head off to. Needs to be full URL."
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3360461882
                                                    ),
                                                    model: {
                                                      value: _vm.mission.answer,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isLink
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "5" } },
                                                [
                                                  _c("rtb-text-input", {
                                                    attrs: {
                                                      label: "Link Label",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "help",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "rtb-inline-help",
                                                                [
                                                                  _vm._v(
                                                                    "The link label that everyone sees"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3310669320
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.mission.linkLabel,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "linkLabel",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.linkLabel",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isFactMatch
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "4" } },
                                                [
                                                  _c("rtb-select", {
                                                    attrs: {
                                                      options:
                                                        _vm.freeFormMissions,
                                                      "option-text": "name",
                                                      label:
                                                        "Connected Mission",
                                                      rules: { required: true },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "help",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "rtb-inline-help",
                                                                [
                                                                  _vm._v(
                                                                    "Connect to Another Mission - Text, Giphy, Photo, Take Photo, Represent, Free Form"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2485282643
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .freeFormMissionID,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "freeFormMissionID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.freeFormMissionID",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isTwoTruthsReveal
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "4" } },
                                                [
                                                  _c("rtb-select", {
                                                    attrs: {
                                                      options:
                                                        _vm.twoTruthsMissions,
                                                      "option-text": "name",
                                                      label:
                                                        "Connected Mission",
                                                      rules: { required: true },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "help",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "rtb-inline-help",
                                                                [
                                                                  _vm._v(
                                                                    "Connect to a Two Truths mission"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      72435039
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .twoTruthsMissionID,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "twoTruthsMissionID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.twoTruthsMissionID",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "1" } },
                                            [
                                              _c("rtb-text-input", {
                                                attrs: {
                                                  label: "Points",
                                                  rules:
                                                    _vm.rules.MissionPoints,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                "The number of points they can earn on a mission"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4213794515
                                                ),
                                                model: {
                                                  value: _vm.mission.points,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "points",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "mission.points",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "1" } },
                                            [
                                              _c("rtb-select", {
                                                attrs: {
                                                  options: _vm.tries,
                                                  label: "Tries",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                "Number of Tries before getting it wrong"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  499366161
                                                ),
                                                model: {
                                                  value: _vm.mission.numOfTries,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "numOfTries",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.numOfTries",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "3" } },
                                            [
                                              _c("rtb-select", {
                                                attrs: {
                                                  options: _vm.playTypes,
                                                  label: "Play Type",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                "Team: One player answers for your team - Individual means everyone answers"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4212598793
                                                ),
                                                model: {
                                                  value: _vm.mission.playType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "playType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.playType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "1" } },
                                            [
                                              _c("rtb-text-input", {
                                                attrs: {
                                                  label: "Time",
                                                  required: "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                "Time Limit in Seconds"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2375986019
                                                ),
                                                model: {
                                                  value: _vm.mission.time,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "time",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "mission.time",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "2" } },
                                            [
                                              _c("rtb-text-input", {
                                                attrs: {
                                                  label:
                                                    "YouTube Code or Video URL",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                " Just the code, not full URL "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2977916170
                                                ),
                                                model: {
                                                  value: _vm.mission.youtube,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "youtube",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "mission.youtube",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "2" } },
                                            [
                                              _c("rtb-text-input", {
                                                attrs: { label: "Start time" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                "Where to start the YouTube video in seconds "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3972419064
                                                ),
                                                model: {
                                                  value: _vm.mission.videoTime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "videoTime",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "mission.videoTime",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "3" } },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { grow: "" } },
                                                    [
                                                      _c("rtb-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.gameAudioTracks,
                                                          "option-text": "name",
                                                          label: "Audio Clue",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "help",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "rtb-inline-help",
                                                                    [
                                                                      _vm._v(
                                                                        " Audio Asset to slide out "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1016240355
                                                        ),
                                                        model: {
                                                          value: _vm.audioClue,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.audioClue = $$v
                                                          },
                                                          expression:
                                                            "audioClue",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.mission.audio
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "copy-icon",
                                                          attrs: { shrink: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  icon: "",
                                                                                  small:
                                                                                    "",
                                                                                  "aria-label":
                                                                                    "Copy Url",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  {
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.copyAudioUrl(
                                                                                            _vm
                                                                                              .mission
                                                                                              .audio
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    " share "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  1191324015
                                                                ),
                                                            },
                                                            [
                                                              !_vm.urlCopyText
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .mission
                                                                          .audio
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.urlCopyText
                                                                      )
                                                                    ),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "3" } },
                                            [
                                              _c("rtb-select", {
                                                attrs: {
                                                  identity: "value",
                                                  options: _vm.AUDIO_OPTIONS,
                                                  label: "User Muting",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                " Tells who can hear who "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1127171415
                                                ),
                                                model: {
                                                  value: _vm.audioOption,
                                                  callback: function ($$v) {
                                                    _vm.audioOption = $$v
                                                  },
                                                  expression: "audioOption",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.mission.behavior == "Word Cloud"
                                            ? _c(
                                                "RtbCol",
                                                { attrs: { xs: "2" } },
                                                [
                                                  _c("RtbTextInput", {
                                                    attrs: {
                                                      label: "Max text Length",
                                                      type: "number",
                                                      rules:
                                                        _vm.rules.MissionPoints,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .maxTextLength,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "maxTextLength",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "mission.maxTextLength",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.mission.behavior == "Freeform"
                                            ? _c(
                                                "rtb-col",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "25px",
                                                  },
                                                  attrs: { xs: "3" },
                                                },
                                                [
                                                  _c("rtb-checkbox", {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      label:
                                                        "Scores are awarded based on the highest number",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .highestNumber,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "highestNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.highestNumber",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.mission.behavior == "Freeform"
                                            ? _c(
                                                "rtb-col",
                                                { attrs: { xs: "3" } },
                                                [
                                                  _c("rtb-text-input", {
                                                    attrs: {
                                                      label: "Host Password",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "help",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "rtb-inline-help",
                                                                [
                                                                  _vm._v(
                                                                    "The password used by the In Person host "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      959302422
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.mission
                                                          .hostPassword,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "hostPassword",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "mission.hostPassword",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isVideoIndividual ||
                                          _vm.isVideoTeam
                                            ? [
                                                _c(
                                                  "rtb-col",
                                                  { attrs: { xs: "2" } },
                                                  [
                                                    _c("rtb-text-input", {
                                                      attrs: {
                                                        rules:
                                                          _vm.rules
                                                            .VideoMissionTimeLimit,
                                                        label:
                                                          "Video Recording Time in seconds",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "help",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "rtb-inline-help",
                                                                  [
                                                                    _vm._v(
                                                                      "Number time of recording in seconds "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1843473457
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.mission
                                                            .videoMaxRecTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.mission,
                                                            "videoMaxRecTime",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "mission.videoMaxRecTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm.game.hostless ||
                                          _vm.game.hostless2
                                            ? [
                                                _c(
                                                  "rtb-col",
                                                  { attrs: { xs: "2" } },
                                                  [
                                                    _c("rtb-text-input", {
                                                      attrs: {
                                                        label:
                                                          "Hostless Video Placeholder",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "help",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "rtb-inline-help",
                                                                  [
                                                                    _vm._v(
                                                                      "Name that will apear on hostless videos while it plays"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        3330436943
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.mission
                                                            .hostlessVideoName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.mission,
                                                            "hostlessVideoName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "mission.hostlessVideoName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          _c(
                                            "RtbCol",
                                            { attrs: { xs: "12" } },
                                            [
                                              _c("RtbTextarea", {
                                                attrs: {
                                                  label: "Instructions",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission.instructions,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "instructions",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.instructions",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "RtbCol",
                                            { attrs: { xs: "12" } },
                                            [
                                              _c("RtbTextarea", {
                                                attrs: {
                                                  label: "Notes",
                                                  rows: "4",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c("RtbInlineHelp", [
                                                            _vm._v(
                                                              " Used for hosts to see additional information about a challenge "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1456235674
                                                ),
                                                model: {
                                                  value: _vm.mission.notes,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "notes",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "mission.notes",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-3" },
                                        _vm._l(
                                          _vm.allModes(_vm.mission),
                                          function (mode, i) {
                                            return _c("rtb-checkbox", {
                                              key: i + "-" + mode.name,
                                              staticClass: "mr-2",
                                              attrs: { label: mode.name },
                                              model: {
                                                value: _vm.mission[mode.name],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.mission,
                                                    mode.name,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "mission[mode.name]",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-3" },
                                        [
                                          _vm.isLink
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  label: "Show Winning Team",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission.showWinningTeam,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "showWinningTeam",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.showWinningTeam",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.isLink
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  label: "Send User Info",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission.sendUserParams,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "sendUserParams",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.sendUserParams",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.isPhotoMission
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  label: "Use for Avatar",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission.useAsAvatar,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "useAsAvatar",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.useAsAvatar",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("rtb-checkbox", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              label: "Disable Action SFX",
                                            },
                                            model: {
                                              value:
                                                _vm.mission.disableActionSfx,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "disableActionSfx",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "mission.disableActionSfx",
                                            },
                                          }),
                                          _c("rtb-checkbox", {
                                            staticClass: "mr-2",
                                            attrs: { label: "Pass Scribe" },
                                            model: {
                                              value: _vm.mission.passScribe,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "passScribe",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.passScribe",
                                            },
                                          }),
                                          _vm.mission.social
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  label:
                                                    "Unlimited Number of Teams",
                                                },
                                                on: {
                                                  change:
                                                    _vm.unlimitedTeamsChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission.unlimitedTeams,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "unlimitedTeams",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.unlimitedTeams",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("rtb-checkbox", {
                                            staticClass: "mr-2",
                                            attrs: { label: "No Vote" },
                                            model: {
                                              value: _vm.mission.noVote,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "noVote",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.noVote",
                                            },
                                          }),
                                          _c("rtb-checkbox", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              label: "Hide Points in Voting",
                                            },
                                            model: {
                                              value:
                                                _vm.mission.hidePointsInVoting,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "hidePointsInVoting",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "mission.hidePointsInVoting",
                                            },
                                          }),
                                          _vm.isCreatePoll
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2",
                                                attrs: { label: "Create Poll" },
                                                model: {
                                                  value: _vm.mission.createPoll,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "createPoll",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.createPoll",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.mission.behavior ==
                                          "Multiple Choice"
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  label:
                                                    "Don't Show Correct Answer",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission.dontShowCorrect,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "dontShowCorrect",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.dontShowCorrect",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.mission.behavior ==
                                          "Multiple Choice"
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  label:
                                                    "Right/Wrong indication off",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission
                                                      .rightWrongIndicationOff,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "rightWrongIndicationOff",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.rightWrongIndicationOff",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.mission.results
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  label:
                                                    "Show Only Mission results",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission
                                                      .showMissionResultsOnly,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "showMissionResultsOnly",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.showMissionResultsOnly",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.isYouTube
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2 ml-2",
                                                attrs: { label: "Watch Party" },
                                                model: {
                                                  value: _vm.mission.watchParty,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "watchParty",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.watchParty",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.isVideo
                                            ? [
                                                _c("rtb-checkbox", {
                                                  staticClass: "mr-2 ml-2",
                                                  attrs: {
                                                    label: "With Audio",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.mission.withAudio,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.mission,
                                                        "withAudio",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "mission.withAudio",
                                                  },
                                                }),
                                                _c("rtb-checkbox", {
                                                  staticClass: "mr-2 ml-2",
                                                  attrs: { label: "Boomerang" },
                                                  model: {
                                                    value:
                                                      _vm.mission.boomerang,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.mission,
                                                        "boomerang",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "mission.boomerang",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          _c("rtb-checkbox", {
                                            staticClass: "mr-2 ml-2",
                                            attrs: {
                                              label:
                                                "One Team Correct To Advance Hostless",
                                            },
                                            model: {
                                              value:
                                                _vm.mission
                                                  .oneTeamCorrectToAdvanceHostless,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "oneTeamCorrectToAdvanceHostless",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "mission.oneTeamCorrectToAdvanceHostless",
                                            },
                                          }),
                                          _vm.game.hostless ||
                                          _vm.game.hostless2
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2 ml-2",
                                                attrs: {
                                                  label:
                                                    "No timer warning popup",
                                                },
                                                model: {
                                                  value: _vm.mission.noPopup,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "noPopup",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "mission.noPopup",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.game.hostless ||
                                          _vm.game.hostless2
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2 ml-2",
                                                attrs: {
                                                  label: "Help Request Issued",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission.helpRequested,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "helpRequested",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.helpRequested",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.mission.voting
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2 ml-2",
                                                attrs: {
                                                  label: "One at a time voting",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission.oneAtTimeVoting,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "oneAtTimeVoting",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.oneAtTimeVoting",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.mission.voting
                                            ? _c("rtb-checkbox", {
                                                staticClass: "mr-2 ml-2",
                                                attrs: {
                                                  label: "Theater Voting",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission.theaterVoting,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "theaterVoting",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.theaterVoting",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _vm.localGame.playthroughID
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c("rtb-checkbox", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  label: "Breadcrumb Hybrid",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission
                                                      .breadcrumbHybrid,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "breadcrumbHybrid",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.breadcrumbHybrid",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.hasMultipleAnswers &&
                                      !(
                                        _vm.mission.behavior == "Poll" &&
                                        _vm.mission.nominateScribe
                                      )
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "rtb-row",
                                                _vm._l(5, function (i) {
                                                  return _c(
                                                    "rtb-col",
                                                    {
                                                      key: i,
                                                      staticStyle: {
                                                        flex: "1 1 0",
                                                      },
                                                    },
                                                    [
                                                      _c("rtb-text-input", {
                                                        attrs: {
                                                          label:
                                                            _vm.isMultipleChoise
                                                              ? "Answer " + i
                                                              : "Option " + i,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "append",
                                                              fn: function () {
                                                                return [
                                                                  _vm.isMultipleChoise
                                                                    ? _c(
                                                                        "input-button",
                                                                        {
                                                                          staticClass:
                                                                            "flex-shrink-0",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                i ===
                                                                                _vm
                                                                                  .mission
                                                                                  .multiCorrect
                                                                                  ? "success"
                                                                                  : undefined,
                                                                              title:
                                                                                "Right",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.mission.multiCorrect =
                                                                                  i
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "svg-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "check-regular",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.mission[
                                                              "answer" + i
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.mission,
                                                              "answer" + i,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "mission[`answer${i}`]",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSlides
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              (!_vm.isKaraokePowerpoint &&
                                                !_vm.hasRepresentImages) ||
                                              (_vm.mission.documentUrl &&
                                                _vm.hasRepresentImages)
                                                ? _c(
                                                    "v-layout",
                                                    { attrs: { column: "" } },
                                                    [
                                                      _c("v-flex", [
                                                        _c("h3", [
                                                          _vm._v(
                                                            "Uploading a PDF takes precedence to images"
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticStyle: {
                                                            width: "40%",
                                                          },
                                                          attrs: {
                                                            shrink: "",
                                                            xs4: "",
                                                            "mb-2": "",
                                                          },
                                                        },
                                                        [
                                                          _c("FileUploader", {
                                                            attrs: {
                                                              label:
                                                                "Upload your document file (.pdf)",
                                                              accept: ["pdf"],
                                                              fileName:
                                                                _vm.fileName,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.mission
                                                                  .documentUrl,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.mission,
                                                                    "documentUrl",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "mission.documentUrl",
                                                            },
                                                          }),
                                                          _c("rtb-checkbox", {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              label:
                                                                "Document downloadable",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.mission
                                                                  .documentDownloadable,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.mission,
                                                                    "documentDownloadable",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "mission.documentDownloadable",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      !_vm.mission
                                                        .documentUrl &&
                                                      !_vm.hasRepresentImages
                                                        ? _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                column: "",
                                                                "mb-2": "",
                                                              },
                                                            },
                                                            [
                                                              _c("v-flex", [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "--- OR Upload Images ---"
                                                                  ),
                                                                ]),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.mission.documentUrl ||
                                              (_vm.mission.documentUrl &&
                                                _vm.hasRepresentImages)
                                                ? _c(
                                                    "v-layout",
                                                    { attrs: { column: "" } },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          attrs: {
                                                            column: "",
                                                            "mb-2": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _vm.slidesLoading ===
                                                                  true
                                                                    ? _c(
                                                                        "RtbSpinner",
                                                                        {
                                                                          staticClass:
                                                                            "mr-1",
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.slidesItemsLeft !==
                                                                  0
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.slidesItemsLeft
                                                                              ) +
                                                                              " left "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mb-3 mt-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "RtbButton",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "success",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.addNewRepresentImage(
                                                                              "Slide"
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Add Slide "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm.hasRepresentImages
                                                                    ? _c(
                                                                        "RtbButton",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "danger",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              _vm.maybeDeleteRepresentImages,
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Delete All Slides "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.isKaraokePowerpoint
                                                                    ? _c(
                                                                        "RtbTextInput",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2",
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Number of Slides Per Team",
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "help",
                                                                                  fn: function () {
                                                                                    return [
                                                                                      _c(
                                                                                        "RtbInlineHelp",
                                                                                        [
                                                                                          _vm._v(
                                                                                            " Number of Slides Per page "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                  proxy: true,
                                                                                },
                                                                              ],
                                                                              null,
                                                                              false,
                                                                              3831687069
                                                                            ),
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .mission
                                                                                  .numOfSlidesPerTeam,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.mission,
                                                                                    "numOfSlidesPerTeam",
                                                                                    _vm._n(
                                                                                      $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "mission.numOfSlidesPerTeam",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "VueDraggable",
                                                                {
                                                                  staticClass:
                                                                    "rtb-row rtb-row--gap--default",
                                                                  model: {
                                                                    value:
                                                                      _vm.slides,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.slides =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "slides",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.slides,
                                                                  function (
                                                                    slide,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "RtbCol",
                                                                      {
                                                                        key: i,
                                                                        staticClass:
                                                                          "mb-2",
                                                                        attrs: {
                                                                          xs: "3",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "ImageUploaderNext",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Slide " +
                                                                                  (i +
                                                                                    1),
                                                                                removeable:
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              remove:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.removeRepresentImage(
                                                                                    i
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .mission
                                                                                    .representImages[
                                                                                    i
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .mission
                                                                                        .representImages,
                                                                                      i,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "mission.representImages[i]",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm
                                                                          .mission
                                                                          .durations
                                                                          ? _c(
                                                                              "RtbTextInput",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "number",
                                                                                    label:
                                                                                      "Slide Timer",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .mission
                                                                                        .durations[
                                                                                        i
                                                                                      ],
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .mission
                                                                                            .durations,
                                                                                          i,
                                                                                          _vm._n(
                                                                                            $$v
                                                                                          )
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "mission.durations[i]",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isMatchGame
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "rtb-button",
                                                {
                                                  staticClass: "mb-2",
                                                  attrs: { color: "success" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addNewRepresentImage(
                                                        ""
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Add new Image ")]
                                              ),
                                              _c("rtb-checkbox", {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  label:
                                                    "Transparent background",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission
                                                      .imagesTransparentBg,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "imagesTransparentBg",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.imagesTransparentBg",
                                                },
                                              }),
                                              _c(
                                                "rtb-row",
                                                { staticClass: "mb-3" },
                                                _vm._l(
                                                  _vm.mission.representImages,
                                                  function (photo, i) {
                                                    return _c(
                                                      "rtb-col",
                                                      {
                                                        key: i,
                                                        attrs: { xs: "3" },
                                                      },
                                                      [
                                                        _c(
                                                          "image-uploader-next",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Represent image " +
                                                                (i + 1),
                                                              removeable: "",
                                                            },
                                                            on: {
                                                              remove: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeRepresentImage(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.mission
                                                                  .representImages[
                                                                  i
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.mission
                                                                      .representImages,
                                                                    i,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "mission.representImages[i]",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                              _c(
                                                "rtb-button",
                                                {
                                                  staticClass: "mb-2",
                                                  attrs: { color: "success" },
                                                  on: {
                                                    click:
                                                      _vm.addNewRepresentImagePair,
                                                  },
                                                },
                                                [_vm._v(" Add Pair Image ")]
                                              ),
                                              _c("rtb-checkbox", {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  label:
                                                    "Transparent background",
                                                },
                                                model: {
                                                  value:
                                                    _vm.mission
                                                      .pairImagesTransparentBg,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.mission,
                                                      "pairImagesTransparentBg",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "mission.pairImagesTransparentBg",
                                                },
                                              }),
                                              _c(
                                                "rtb-row",
                                                _vm._l(
                                                  _vm.mission
                                                    .representImagesPair,
                                                  function (photo, i) {
                                                    return _c(
                                                      "rtb-col",
                                                      {
                                                        key: i,
                                                        attrs: { xs: "3" },
                                                      },
                                                      [
                                                        _c(
                                                          "image-uploader-next",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Represent Pair " +
                                                                (i + 1),
                                                              removeable: "",
                                                            },
                                                            on: {
                                                              remove: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeRepresentPairImage(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.mission
                                                                  .representImagesPair[
                                                                  i
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.mission
                                                                      .representImagesPair,
                                                                    i,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "mission.representImagesPair[i]",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isRoyalRumble
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "rtb-button",
                                                {
                                                  staticClass: "mb-2",
                                                  attrs: { color: "success" },
                                                  on: {
                                                    click:
                                                      _vm.addNewRepresentImage,
                                                  },
                                                },
                                                [_vm._v(" Add new Image ")]
                                              ),
                                              _c(
                                                "rtb-row",
                                                _vm._l(
                                                  _vm.mission.representImages,
                                                  function (photo, i) {
                                                    return _c(
                                                      "rtb-col",
                                                      {
                                                        key: i,
                                                        attrs: { xs: "3" },
                                                      },
                                                      [
                                                        _c(
                                                          "image-uploader-next",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Represent image " +
                                                                (i + 1),
                                                              removeable: "",
                                                            },
                                                            on: {
                                                              remove: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeRepresentImage(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.mission
                                                                  .representImages[
                                                                  i
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.mission
                                                                      .representImages,
                                                                    i,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "mission.representImages[i]",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isOrderTheCardsGame
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "rtb-col",
                                                [
                                                  _c(
                                                    "rtb-row",
                                                    [
                                                      _c(
                                                        "rtb-button",
                                                        {
                                                          staticClass: "mb-2",
                                                          attrs: {
                                                            color: "success",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addMissionImage(
                                                                "orderCardsTop"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Add Top Card")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "rtb-row",
                                                    _vm._l(
                                                      _vm.mission.orderCardsTop,
                                                      function (photo, i) {
                                                        return _c(
                                                          "rtb-col",
                                                          {
                                                            key: i,
                                                            attrs: { xs: "3" },
                                                          },
                                                          [
                                                            _c(
                                                              "image-uploader-next",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "Order Card Front " +
                                                                    (i + 1),
                                                                  removeable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  remove:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeMissionImage(
                                                                        i,
                                                                        "orderCardsTop"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.mission
                                                                      .orderCardsTop[
                                                                      i
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .mission
                                                                          .orderCardsTop,
                                                                        i,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "mission.orderCardsTop[i]",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "rtb-col",
                                                { staticClass: "mt-3" },
                                                [
                                                  _c(
                                                    "rtb-button",
                                                    {
                                                      staticClass: "mb-2",
                                                      attrs: {
                                                        color: "success",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addMissionImage(
                                                            "orderCardsBottom"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Add Card")]
                                                  ),
                                                  _c(
                                                    "rtb-row",
                                                    _vm._l(
                                                      _vm.mission
                                                        .orderCardsBottom,
                                                      function (photo, i) {
                                                        return _c(
                                                          "rtb-col",
                                                          {
                                                            key: i,
                                                            attrs: { xs: "3" },
                                                          },
                                                          [
                                                            _c(
                                                              "image-uploader-next",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "Order Card Back " +
                                                                    (i + 1),
                                                                  removeable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  remove:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeMissionImage(
                                                                        i,
                                                                        "orderCardsBottom"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.mission
                                                                      .orderCardsBottom[
                                                                      i
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .mission
                                                                          .orderCardsBottom,
                                                                        i,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "mission.orderCardsBottom[i]",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isWhiteElephant
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "rtb-button",
                                                {
                                                  staticClass: "mb-2",
                                                  attrs: { color: "success" },
                                                  on: {
                                                    click:
                                                      _vm.addWhiteElephantImage,
                                                  },
                                                },
                                                [_vm._v("Add new Image")]
                                              ),
                                              _c(
                                                "rtb-row",
                                                _vm._l(
                                                  _vm.mission
                                                    .whiteElephantImages,
                                                  function (obj, i) {
                                                    return _c(
                                                      "rtb-col",
                                                      {
                                                        key: i,
                                                        attrs: { xs: "3" },
                                                      },
                                                      [
                                                        _c("rtb-text-input", {
                                                          attrs: {
                                                            label: "Gift Label",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.mission
                                                                .whiteElephantImages[
                                                                i
                                                              ].label,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.mission
                                                                  .whiteElephantImages[
                                                                  i
                                                                ],
                                                                "label",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "mission.whiteElephantImages[i].label",
                                                          },
                                                        }),
                                                        _c("rtb-text-input", {
                                                          attrs: {
                                                            label: "Gift Url",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.mission
                                                                .whiteElephantImages[
                                                                i
                                                              ].url,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.mission
                                                                  .whiteElephantImages[
                                                                  i
                                                                ],
                                                                "url",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "mission.whiteElephantImages[i].url",
                                                          },
                                                        }),
                                                        _c(
                                                          "image-uploader-next",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.mission
                                                                  .whiteElephantImages[
                                                                  i
                                                                ].label ||
                                                                "Image " +
                                                                  (i + 1),
                                                              removeable: "",
                                                            },
                                                            on: {
                                                              remove: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeWhiteElephantImage(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.mission
                                                                  .whiteElephantImages[
                                                                  i
                                                                ].image,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.mission
                                                                      .whiteElephantImages[
                                                                      i
                                                                    ],
                                                                    "image",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "mission.whiteElephantImages[i].image",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isPhotoBooth
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "rtb-button",
                                                {
                                                  staticClass: "mb-2",
                                                  attrs: { color: "success" },
                                                  on: {
                                                    click:
                                                      _vm.addNewPhotoBoothImage,
                                                  },
                                                },
                                                [_vm._v("Add new Image")]
                                              ),
                                              _c(
                                                "rtb-row",
                                                _vm._l(
                                                  _vm.mission.photoboothImages,
                                                  function (photo, i) {
                                                    return _c(
                                                      "rtb-col",
                                                      {
                                                        key: i + "-image",
                                                        attrs: { xs: "3" },
                                                      },
                                                      [
                                                        _c(
                                                          "image-uploader-next",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Photobooth image " +
                                                                (i + 1),
                                                              removeable: "",
                                                            },
                                                            on: {
                                                              remove: function (
                                                                $event
                                                              ) {
                                                                return _vm.removePhotoBoothImage(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.mission
                                                                  .photoboothImages[
                                                                  i
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.mission
                                                                      .photoboothImages,
                                                                    i,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "mission.photoboothImages[i]",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isRatingPoll
                                        ? _c(
                                            "rtb-row",
                                            { staticClass: "mt-2" },
                                            _vm._l(
                                              _vm.numberOfLinkedMissions,
                                              function (i) {
                                                return _c(
                                                  "rtb-col",
                                                  {
                                                    key: i + "-rating",
                                                    attrs: { xs: "3" },
                                                  },
                                                  [
                                                    _c("rtb-select", {
                                                      attrs: {
                                                        value:
                                                          _vm.getLikedMissionId(
                                                            i - 1
                                                          ),
                                                        options:
                                                          _vm.ratingPollMissions,
                                                        label:
                                                          "Linked Mission - " +
                                                          i,
                                                        "option-text": "name",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.linkedMissionChange(
                                                            i - 1,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.copying
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "rtb-row",
                                                [
                                                  _vm.user.super
                                                    ? _c(
                                                        "rtb-col",
                                                        { attrs: { xs: "3" } },
                                                        [
                                                          _c("rtb-select", {
                                                            attrs: {
                                                              value:
                                                                _vm.newOrgID,
                                                              options: _vm.orgs,
                                                              label: "Org",
                                                              "option-text":
                                                                "name",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.updateOrgID,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  !_vm.isDbShard
                                                    ? [
                                                        _c(
                                                          "rtb-col",
                                                          {
                                                            attrs: { xs: "3" },
                                                          },
                                                          [
                                                            _c("rtb-select", {
                                                              attrs: {
                                                                options:
                                                                  _vm.games,
                                                                "option-text":
                                                                  "name",
                                                                label:
                                                                  "Copy To Game",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newGameID,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.newGameID =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "newGameID",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "rtb-col",
                                                          {
                                                            attrs: { xs: "3" },
                                                          },
                                                          [
                                                            _c(
                                                              "rtb-text-input",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "Copy To Game",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.newGameID,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.newGameID =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "newGameID",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "rtb-tab",
                            { key: "images", attrs: { title: "Images" } },
                            [
                              _c(
                                "rtb-card-body",
                                [
                                  _c(
                                    "rtb-row",
                                    [
                                      _c(
                                        "rtb-col",
                                        { attrs: { xs: "3" } },
                                        [
                                          _c("image-uploader-next", {
                                            attrs: { label: "Photo Asset" },
                                            model: {
                                              value: _vm.mission.photo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "photo",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.photo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "rtb-col",
                                        { attrs: { xs: "3" } },
                                        [
                                          _c("image-uploader-next", {
                                            attrs: {
                                              label: "Instructions Image",
                                            },
                                            model: {
                                              value:
                                                _vm.mission.instructionsPhoto,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "instructionsPhoto",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "mission.instructionsPhoto",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "rtb-col",
                                        { attrs: { xs: "3" } },
                                        [
                                          _c("image-uploader-next", {
                                            attrs: {
                                              label: "Background Image",
                                            },
                                            model: {
                                              value:
                                                _vm.mission.backgroundPhoto,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "backgroundPhoto",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "mission.backgroundPhoto",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  !!_vm.mission.backgroundPhoto
                                    ? _c(
                                        "rtb-row",
                                        [
                                          _c(
                                            "rtb-col",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                                "align-items": "center",
                                                "font-size": "18px",
                                                color: "#fff",
                                              },
                                              attrs: { xs: "2" },
                                            },
                                            [
                                              _vm._v(
                                                " Background Opacity " +
                                                  _vm._s(
                                                    _vm.missionBackgroundOpacity
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "10" } },
                                            [
                                              _c("v-slider", {
                                                attrs: { max: 100, step: 1 },
                                                model: {
                                                  value:
                                                    _vm.missionBackgroundOpacity,
                                                  callback: function ($$v) {
                                                    _vm.missionBackgroundOpacity =
                                                      $$v
                                                  },
                                                  expression:
                                                    "missionBackgroundOpacity",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("rtb-checkbox", {
                                    attrs: { label: "Download Asset" },
                                    model: {
                                      value: _vm.mission.autoDownloadPhotoAsset,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.mission,
                                          "autoDownloadPhotoAsset",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "mission.autoDownloadPhotoAsset",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "rtb-tab",
                            { key: "auto", attrs: { title: "Audio Elements" } },
                            [
                              _c(
                                "rtb-card-body",
                                [
                                  _c(
                                    "rtb-row",
                                    [
                                      _c(
                                        "rtb-col",
                                        { attrs: { xs: "3" } },
                                        [
                                          _c("rtb-select", {
                                            attrs: {
                                              options: _vm.gameAudioTracks,
                                              "option-text": "name",
                                              label: "Auto Mission Audio",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "help",
                                                  fn: function () {
                                                    return [
                                                      _c("rtb-inline-help", [
                                                        _vm._v(
                                                          " Triggered Audio at the start of the mission "
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2421506360
                                            ),
                                            model: {
                                              value: _vm.missionAudioTrack,
                                              callback: function ($$v) {
                                                _vm.missionAudioTrack = $$v
                                              },
                                              expression: "missionAudioTrack",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "rtb-button",
                                        {
                                          staticClass: "align-self-end my-2",
                                          attrs: {
                                            color: "danger",
                                            variant: "icon",
                                            "aria-label": "Delete",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.missionAudioTrack = null
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: { name: "trash-regular" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.mission.flagged
                            ? _c(
                                "rtb-tab",
                                { key: "auto", attrs: { title: "Flagged" } },
                                [
                                  _c(
                                    "rtb-card-body",
                                    [
                                      _c(
                                        "rtb-row",
                                        [
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "3" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "white",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.mission.flagged)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "rtb-tab",
                            { key: "Hints", attrs: { title: "Hints" } },
                            [
                              _c(
                                "rtb-card-body",
                                [
                                  _c(
                                    "rtb-row",
                                    _vm._l(10, function (i) {
                                      return _c(
                                        "rtb-col",
                                        { key: i, attrs: { xs: "6" } },
                                        [
                                          _c("rtb-text-input", {
                                            attrs: { label: "Hint " + i },
                                            model: {
                                              value: _vm.hints[i - 1],
                                              callback: function ($$v) {
                                                _vm.$set(_vm.hints, i - 1, $$v)
                                              },
                                              expression: "hints[i - 1]",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "rtb-tab",
                            { key: "auto", attrs: { title: "Branching" } },
                            [
                              _c(
                                "rtb-card-body",
                                [
                                  _c(
                                    "rtb-row",
                                    [
                                      _c(
                                        "rtb-col",
                                        { attrs: { xs: "3" } },
                                        [
                                          _c("rtb-select", {
                                            attrs: {
                                              options: _vm.allMissions,
                                              "option-text": "name",
                                              label: "Correct - Go To Mission",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "help",
                                                  fn: function () {
                                                    return [
                                                      _c("rtb-inline-help", [
                                                        _vm._v(
                                                          "If everyone gets it right, go to this mission"
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2727225500
                                            ),
                                            model: {
                                              value:
                                                _vm.mission.correctMissionID,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "correctMissionID",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "mission.correctMissionID",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "rtb-col",
                                        { attrs: { xs: "3" } },
                                        [
                                          _c("rtb-select", {
                                            attrs: {
                                              options: _vm.allMissions,
                                              "option-text": "name",
                                              label:
                                                "Incorrect - Go To Mission",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "help",
                                                  fn: function () {
                                                    return [
                                                      _c("rtb-inline-help", [
                                                        _vm._v(
                                                          "If everyone gets it right, go to this mission"
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2727225500
                                            ),
                                            model: {
                                              value:
                                                _vm.mission.incorrectMissionID,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "incorrectMissionID",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "mission.incorrectMissionID",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.mission.behavior == "Poll"
                                    ? _c(
                                        "h3",
                                        {
                                          staticClass: "text-white",
                                          staticStyle: { "margin-top": "20px" },
                                          attrs: { "mt-3": "" },
                                        },
                                        [_vm._v(" Poll Branching ")]
                                      )
                                    : _vm._e(),
                                  _vm.mission.behavior == "Poll"
                                    ? _c("rtb-row", [
                                        _vm.hasMultipleAnswers
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-3" },
                                              [
                                                _c(
                                                  "rtb-row",
                                                  _vm._l(5, function (i) {
                                                    return _c(
                                                      "rtb-col",
                                                      {
                                                        key: i,
                                                        attrs: { xs: "4" },
                                                      },
                                                      [
                                                        _c("rtb-text-input", {
                                                          attrs: {
                                                            label:
                                                              _vm.isMultipleChoise
                                                                ? "Answer " + i
                                                                : "Option " + i,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.mission[
                                                                "answer" + i
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.mission,
                                                                "answer" + i,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "mission[`answer${i}`]",
                                                          },
                                                        }),
                                                        _c("rtb-select", {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            options:
                                                              _vm.allMissions,
                                                            "option-text":
                                                              "name",
                                                            label:
                                                              "Go To This Mission",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.mission[
                                                                "branchMissionID" +
                                                                  i
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.mission,
                                                                "branchMissionID" +
                                                                  i,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "mission[`branchMissionID${i}`]",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "rtb-tab",
                            { key: "breakout", attrs: { title: "Breakout" } },
                            [
                              _c(
                                "rtb-card-body",
                                [
                                  _c(
                                    "RtbRow",
                                    [
                                      _c(
                                        "RtbCol",
                                        { attrs: { xs: "4" } },
                                        [
                                          _c("RtbSelect", {
                                            staticClass: "mb-3",
                                            attrs: {
                                              label: "Choose Breakout Option",
                                              identity: "value",
                                              optionText: "label",
                                              clearable: "",
                                              options: _vm.breakoutOptions,
                                            },
                                            model: {
                                              value: _vm.breakoutOption,
                                              callback: function ($$v) {
                                                _vm.breakoutOption = $$v
                                              },
                                              expression: "breakoutOption",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.breakoutOption &&
                                      _vm.breakoutOption !==
                                        _vm.BreakoutType.UNDO
                                        ? _c(
                                            "RtbCol",
                                            { attrs: { xs: "4" } },
                                            [
                                              _c("RtbTextInput", {
                                                attrs: {
                                                  label:
                                                    "Name of breakout teams",
                                                },
                                                model: {
                                                  value: _vm.breakoutTeamsName,
                                                  callback: function ($$v) {
                                                    _vm.breakoutTeamsName = $$v
                                                  },
                                                  expression:
                                                    "breakoutTeamsName",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "RtbRow",
                                    [
                                      _c(
                                        "RtbCol",
                                        { attrs: { xs: "4" } },
                                        [
                                          _vm.breakoutOption ===
                                          _vm.BreakoutType.BY_NUMBER_OF_TEAMS
                                            ? [
                                                _c("RtbTextInput", {
                                                  staticClass: "mt-3",
                                                  attrs: {
                                                    label:
                                                      "BREAK INTO NUMBER OF TEAMS",
                                                  },
                                                  model: {
                                                    value: _vm.nOfBreakoutTeams,
                                                    callback: function ($$v) {
                                                      _vm.nOfBreakoutTeams =
                                                        _vm._n($$v)
                                                    },
                                                    expression:
                                                      "nOfBreakoutTeams",
                                                  },
                                                }),
                                                _c("RtbCheckbox", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    label:
                                                      "Keep original teams together",
                                                  },
                                                  model: {
                                                    value: _vm.keepTeams,
                                                    callback: function ($$v) {
                                                      _vm.keepTeams = $$v
                                                    },
                                                    expression: "keepTeams",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          _vm.breakoutOption ===
                                          _vm.BreakoutType.BY_SIZE
                                            ? [
                                                _c("RtbSelect", {
                                                  attrs: {
                                                    label:
                                                      "BREAKOUT INTO USER PER TEAM",
                                                    options:
                                                      _vm.numOfBreakoutPlayersArr,
                                                  },
                                                  model: {
                                                    value: _vm.size,
                                                    callback: function ($$v) {
                                                      _vm.size = $$v
                                                    },
                                                    expression: "size",
                                                  },
                                                }),
                                                _c("RtbCheckbox", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    label:
                                                      "Allow team with a single player",
                                                  },
                                                  model: {
                                                    value: _vm.allowSingle,
                                                    callback: function ($$v) {
                                                      _vm.allowSingle = $$v
                                                    },
                                                    expression: "allowSingle",
                                                  },
                                                }),
                                              ]
                                            : _vm.breakoutOption ===
                                              _vm.BreakoutType.BY_POLL
                                            ? [
                                                _vm.pollMissionsData.length
                                                  ? _c("RtbSelect", {
                                                      attrs: {
                                                        identity: "value",
                                                        optionText: "label",
                                                        label:
                                                          "Breakout By Poll Response",
                                                        options:
                                                          _vm.pollMissionsData,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.breakoutMissionID,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.breakoutMissionID =
                                                            $$v
                                                        },
                                                        expression:
                                                          "breakoutMissionID",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c("RtbCheckbox", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    label:
                                                      "Same answers to different teams",
                                                  },
                                                  model: {
                                                    value: _vm.toDifferentTeams,
                                                    callback: function ($$v) {
                                                      _vm.toDifferentTeams = $$v
                                                    },
                                                    expression:
                                                      "toDifferentTeams",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "rtb-tab",
                            {
                              key: "HostlessVideoSelector",
                              attrs: { title: "Hostless Video Selector" },
                            },
                            [
                              _c("HostlessVideoSelector", {
                                attrs: {
                                  mission: _vm.mission,
                                  modalOpen: _vm.editing,
                                  updateLocalMission: _vm.onUpdateLocalMission,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "rtb-tab",
                            {
                              key: "hostlessDefaults",
                              attrs: { title: "Hostless Defaults" },
                            },
                            [
                              _c(
                                "rtb-card-body",
                                [
                                  _c(
                                    "rtb-row",
                                    { staticClass: "mt-3" },
                                    [
                                      _c(
                                        "rtb-col",
                                        { attrs: { xs: "4" } },
                                        [
                                          _c("rtb-text-input", {
                                            attrs: {
                                              label: "Hostless Default Video",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "help",
                                                  fn: function () {
                                                    return [
                                                      _c("rtb-inline-help", [
                                                        _vm._v(
                                                          "The default hostless video that plays if no other video is available"
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2273880459
                                            ),
                                            model: {
                                              value:
                                                _vm.localGame
                                                  .hostlessDefaultVideo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.localGame,
                                                  "hostlessDefaultVideo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localGame.hostlessDefaultVideo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "rtb-col",
                                        { attrs: { xs: "3" } },
                                        [
                                          _c("rtb-text-input", {
                                            attrs: {
                                              label:
                                                "Hostless Video Placeholder",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "help",
                                                  fn: function () {
                                                    return [
                                                      _c("rtb-inline-help", [
                                                        _vm._v(
                                                          "Name that will apear on hostless videos while it plays"
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              3330436943
                                            ),
                                            model: {
                                              value:
                                                _vm.localGame.hostlessVideoName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.localGame,
                                                  "hostlessVideoName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localGame.hostlessVideoName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "rtb-card-actions",
                        [
                          _vm.game.runStatus == "Masters"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "200px",
                                    color: "#54172d",
                                    border: "2px solid black",
                                    "background-color": "#fff",
                                    "border-radius": "5px",
                                    padding: "5px 10px",
                                    "font-weight": "bold",
                                  },
                                },
                                [_vm._v("YOU ARE EDITING A MASTER")]
                              )
                            : _vm._e(),
                          _vm.mission.contentToolID
                            ? _c(
                                "a",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    href:
                                      "/content/activity/" +
                                      _vm.mission.contentToolID,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Content Tool")]
                              )
                            : _vm._e(),
                          _vm.loading
                            ? _c("rtb-spinner", { staticClass: "mr-2" })
                            : _vm._e(),
                          _c(
                            "RtbButton",
                            {
                              attrs: { disabled: _vm.exporting },
                              on: { click: _vm.exportActivity },
                            },
                            [_vm._v("Export")]
                          ),
                          _c(
                            "rtb-button",
                            {
                              attrs: { color: "dark", disabled: _vm.loading },
                              on: {
                                click: function ($event) {
                                  _vm.editing = false
                                  _vm.copying = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _vm.copying
                            ? _c(
                                "rtb-button",
                                {
                                  attrs: { color: "secondary" },
                                  on: { click: _vm.update },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: { name: "copy-regular" },
                                  }),
                                  _vm._v(" Copy"),
                                ],
                                1
                              )
                            : _c(
                                "rtb-button",
                                {
                                  attrs: { disabled: !_vm.isWriteAvailable },
                                  on: { click: _vm.update },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: { name: "save-regular" },
                                  }),
                                  _vm._v(" Save"),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !!_vm.passedGame && _vm.editGameDialog
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    persistent: "",
                    "no-click-animation": "",
                    width: "60%",
                  },
                  model: {
                    value: _vm.editGameDialog,
                    callback: function ($$v) {
                      _vm.editGameDialog = $$v
                    },
                    expression: "editGameDialog",
                  },
                },
                [
                  _c("GameSettings", {
                    attrs: { game: _vm.passedGame },
                    on: {
                      close: function ($event) {
                        return _vm.closeEditSettings()
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("Audio"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }