<script>
const STEP = {
  INITIAL: "INITIAL",
  PREPARE: "PREPARE",
  RECORD: "RECORD",
  SUBMIT: "SUBMIT",
  UPLOAD: "UPLOAD"
}

/**
 * @param {string} url
 * @returns {string}
 */
function extractFileNameFromURL(url) {
  const buffer = url.split("/")
  return buffer[buffer.length - 1]
}

import uniqid from "uniqid"
import RecordVideo from "@/components/Recording/RecordVideo.vue"

export default RecordVideo.extend({
  name: "HostlessVideoRecorder",
  data() {
    return {
      STEP,
      mediaStream: null
    }
  },
  watch: {
    mediaStream: {
      handler(val) {
        if (val === undefined) {
          this.mediaStream = null
        }
      },
      immediate: true
    }
  },
  props: {
    clientID: {
      required: false
    },
    videoTarget: {
      required: false
    },
    videos: {
      type: Object
    }
  },
  methods: {
    async uploadVideo(blob) {
      this.uploadingVideo = true
      this.step = STEP.UPLOAD

      const fileExtension = blob.type.replace(/.*\//gi, ".")
      const fileName = `${uniqid()}-recorded-file-${this.user.id}${
        fileExtension || ".webm"
      }`

      try {
        const url = await this.uploadBlob(blob, fileName)
        this.uploadingVideo = false

        await this.$store.dispatch("recording/addHostlessVideo", {
          clientID: this.clientID,
          name: fileName,
          url,
          flipped: this.isFlipped,
          target: this.videoTarget,
          userID: this.user.id,
          title: this.titleComputed,
          playingTimestamp: this.playingTimestampComputed
        })

        this.data = []
        this.title = ""
        this.step = STEP.INITIAL
      } catch (e) {
        console.error(e)
        this.step = STEP.INITIAL
      } finally {
        this.videoToUpload = null
        await this.getVideos()
      }
    },
    async postAnnouncement() {
      const valid = this.validateForm()
      if (valid) {
        if (this.videoToUpload) {
          this.uploadVideo(this.videoToUpload, true)
        } else if (this.recordedBlob) {
          this.uploadVideo(this.recordedBlob)
        } else if (this.recordingSrc) {
          const { recordingSrc: url } = this
          await this.$store.dispatch("recording/addHostlessVideo", {
            url,
            name: extractFileNameFromURL(url),
            clientID: this.clientID,
            flipped: this.isFlipped,
            target: this.videoTarget,
            userID: this.user.id,
            title: this.titleComputed,
            playingTimestamp: this.playingTimestampComputed
          })
          this.title = ""
          this.step = STEP.INITIAL
        }
      }
      await this.getVideos()
    },
    async getVideos() {
      this.storedVideos = this.videos

      if (this.storedVideos !== null) {
        if (this.normalizedVideos.length > 0) {
          this.currentVideoID =
            this.normalizedVideos[this.normalizedVideos.length - 1].id
        }
      }
    },
    async onDeleteVideo() {
      if (confirm("Are you sure you want to delete this video?")) {
        const { clientID } = this
        await this.$store.dispatch("recording/deleteHostlessVideo", {
          clientID,
          recordingID: this.currentVideoID
        })
        await this.getVideos()
      }
    },
    async onDeleteVideos() {
      if (confirm("Are you sure you want to delete all videos?")) {
        const { clientID } = this
        await this.$store.dispatch("recording/deleteHostlessVideos", {
          clientID,
          targetID: this.getTargetID()
        })
        await this.getVideos()
      }
    },
    async onEditFormSubmit() {
      const valid = this.$refs.editingForm.validate()

      if (valid) {
        await this.$store.dispatch(`recording/updateHostlessVideo`, {
          clientID: this.clientID,
          recordingID: this.currentVideoID,
          title: this.editTitle,
          playingTimestamp: this.playingTimestampComputed
        })
        this.currentVideo.title = this.editTitle
        this.editing = false

        this.getVideos()
      }
    }
  }
})
</script>
